import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Link from 'next/link';

import CustomA from 'components/ui/links/CustomA';
import { NAV_LINKS } from 'domain/constants/NAV_LINKS';

const FooterNav: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {NAV_LINKS.map(({ href, title }) => (
        <Link href={href} key={href} passHref>
          <CustomA className={classes.item}>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </CustomA>
        </Link>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    borderBottom: `2px solid ${theme.palette.common.white}`,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
      borderBottom: 'none',
      alignItems: 'flex-start'
    }
  },
  item: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    ['&:hover']: {
      color: theme.palette.primary.main
    }
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 500
  }
}));

export default FooterNav;
