import React from 'react';
import { IconButton, makeStyles, Box, Drawer as MuiDrawer } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

import LinkedLogo from './Shared/LinkedLogo';
import NavItems from './Shared/NavItems';

interface HeaderProps {
  showDrawer: boolean;
  closeDrawer: () => void;
  onOpenCart: () => void;
}

const Drawer: React.FC<HeaderProps> = ({
  showDrawer,
  closeDrawer,
  onOpenCart
}: HeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiDrawer
      anchor="left"
      open={showDrawer}
      onClose={closeDrawer}
      classes={{ paper: classes.drawerContent }}
    >
      <IconButton aria-label="close drawer" onClick={closeDrawer} className={classes.closeButton}>
        <CloseOutlined />
      </IconButton>

      <Box className={classes.logoContainer}>
        <LinkedLogo />
      </Box>

      <NavItems inDrawer onOpenCart={onOpenCart} />
    </MuiDrawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'white',
    alignItems: 'flex-start'
  },
  closeButton: {
    position: 'absolute',
    color: theme.palette.text.secondary,
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  logoContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default Drawer;
