import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import logo from 'public/logo.svg';
import whiteLogo from 'public/logo-white.svg';
import CustomA from 'components/ui/links/CustomA';
import { useWidth } from 'utils/hooks/useWidth';

interface Props {
  white?: boolean;
  heigth?: number;
  width?: number;
}

const LinkedLogo: React.FC<Props> = ({ white = false, heigth, width = 122 }): JSX.Element => {
  const mq = useWidth();

  return (
    <Link href="/" passHref>
      <CustomA>
        <Image
          src={white ? whiteLogo : logo}
          alt="Totem logo"
          height={heigth || (mq === 'xs' || mq === 'sm' ? 90 : 151)}
          width={width}
        />
      </CustomA>
    </Link>
  );
};

export default LinkedLogo;
