import React, { useMemo } from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';

import LinkedLogo from '../Shared/LinkedLogo';

const Copyright: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box className={classes.copyrightContainer}>
      <LinkedLogo white />

      <Typography className={classes.copyrightText}>©TotemNotes, {year}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  copyrightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingBottom: theme.spacing(4),
    borderBottom: `2px solid ${theme.palette.common.white}`,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none'
    }
  },
  copyrightText: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(2)
  }
}));

export default Copyright;
