import React from 'react';
import Head from 'next/head';
import { DEFAULT_META_DATA } from 'domain/constants';

interface Props {
  title?: string;
  description?: string;
  imageUrl?: string;
}

const MetaTags: React.FC<Props> = ({ title, description, imageUrl }): JSX.Element => {
  return (
    <Head>
      <title>{title || DEFAULT_META_DATA.title}</title>
      <meta name="description" content={description || DEFAULT_META_DATA.description} />

      <meta property="og:title" content={title || DEFAULT_META_DATA.title} />
      <meta property="og:image" content={imageUrl || DEFAULT_META_DATA.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description || DEFAULT_META_DATA.description} />
      <meta property="og:locale" content="ua" />
    </Head>
  );
};

export default MetaTags;
