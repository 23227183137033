import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import Header from './Header';
import Drawer from './Drawer';
import Footer from './Footer/Footer';
import { PageMeta } from 'domain/types';
import { openCart } from 'utils/apollo/vars/showCartVar';
import MetaTags from './MetaTags';

interface Props {
  children: React.ReactNode;
  pageMeta: PageMeta;
  minimal?: boolean;
  showTitleTag?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  pageMeta: { title, description, imageUrl },
  minimal = false,
  showTitleTag
}): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (isDesktop) setShowDrawer(false);
  }, [isDesktop]);

  const handleCloseDrawer = () => setShowDrawer(false);
  const handleOpenDrawer = () => setShowDrawer(true);

  return (
    <>
      <MetaTags title={title} description={description} imageUrl={imageUrl} />

      <Header onOpenDrawer={handleOpenDrawer} onOpenCart={openCart} hideNavigation={minimal} />

      {!isDesktop && !minimal && (
        <Drawer showDrawer={showDrawer} closeDrawer={handleCloseDrawer} onOpenCart={openCart} />
      )}

      {showTitleTag && (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}

      {children}

      <Footer hideNavigation={minimal} />
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    fontSize: '1.75rem',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6),
      fontSize: '2.125rem'
    }
  }
}));

export default Layout;
