import React from 'react';
import { makeStyles, AppBar, Toolbar, Typography, IconButton, Box } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import LinkedLogo from './Shared/LinkedLogo';
import NavItems from './Shared/NavItems';
import CartHeaderIcon from 'components/cart/CartHeaderIcon';

interface HeaderProps {
  pageTitle?: string;
  onOpenDrawer: () => void;
  onOpenCart: () => void;
  hideNavigation?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  pageTitle,
  onOpenDrawer,
  onOpenCart,
  hideNavigation = false
}): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="transparent" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoContainer}>
          <LinkedLogo />
        </Box>

        <Typography variant="h6" component="h1" className={classes.title}>
          {pageTitle}
        </Typography>

        {!hideNavigation && (
          <>
            <NavItems onOpenCart={onOpenCart} />

            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={onOpenDrawer}
            >
              <Menu />
            </IconButton>
          </>
        )}

        <CartHeaderIcon onClick={onOpenCart} className={classes.cartButton} />
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  appBar: {
    marginBottom: theme.spacing(4)
  },
  toolbar: {
    flexDirection: 'column',
    paddingTop: 60
  },
  logoContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 65
    }
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  cartButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default Header;
