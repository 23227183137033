import React from 'react';
import Link from 'next/link';
import { Container, makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import CartHeaderIcon from 'components/cart/CartHeaderIcon';
import { NAV_LINKS } from 'domain/constants/NAV_LINKS';

interface NavItemsProps {
  inDrawer?: boolean;
  onOpenCart: () => void;
}

const NavItems: React.FC<NavItemsProps> = ({ inDrawer, onOpenCart }): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useRouter();

  const isActive = (href: string) => pathname.includes(href);

  return (
    <Container className={classnames(classes.container, inDrawer && classes.containerInDrawer)}>
      {NAV_LINKS.map(({ href, title }) => (
        <Link href={href} key={href} passHref>
          <a className={classnames(classes.item, inDrawer && classes.itemInDrawer)}>
            <Typography
              variant="h4"
              className={classnames(classes.title, isActive(href) && classes.activeItem)}
            >
              {title}
            </Typography>
          </a>
        </Link>
      ))}

      <CartHeaderIcon
        onClick={onOpenCart}
        className={classnames(inDrawer && classes.cartIconInDrawer)}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  item: {
    textDecoration: 'none',
    marginRight: theme.spacing(4),
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main
    }
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    textAlign: 'left',
    '&:hover': {
      color: theme.palette.text.secondary
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    }
  },
  activeItem: {
    color: theme.palette.primary.main
  },
  containerInDrawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  itemInDrawer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  cartIconInDrawer: {
    marginLeft: -theme.spacing(1.5)
  }
}));

export default NavItems;
