import React from 'react';
import { Container, Divider, makeStyles, Grid, Box } from '@material-ui/core';
import classnames from 'classnames';

import FooterNav from './FooterNav';
import Contacts from './Contacts';
import Copyright from './Copyright';

interface FooterProps {
  hideNavigation?: boolean;
}

const Footer: React.FC<FooterProps> = ({ hideNavigation = false }): JSX.Element => {
  const classes = useStyles();

  if (hideNavigation) {
    return (
      <Box className={classes.container}>
        <Container component="footer" className={classes.container}>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Container component="footer">
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.relative}>
            <Copyright />

            <Divider className={classnames(classes.divider, classes.dividerCopyright)} />
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.relative}>
            <FooterNav />

            <Divider className={classnames(classes.divider, classes.dividerNav)} />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Contacts />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(7.5),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.primary.light
  },
  relative: {
    position: 'relative'
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    height: '90%',
    width: 1,
    display: 'none',
    position: 'absolute',
    top: '5%',
    right: 0
  },
  dividerCopyright: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      right: theme.spacing(0.5)
    }
  },
  dividerNav: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
      right: -theme.spacing(0.5)
    }
  }
}));

export default Footer;
